import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
// import emailjs from "emailjs-com";
import { Box, Typography, Grid, Button, Card, TextField } from "@mui/material";
import emailjs from '@emailjs/browser';

const useStyle = makeStyles(() => ({

  bgStyle:{
    // backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
  backgroundRepeat: "no-repeat",


  },

  formStyle: {
    "&.MuiBox-root": {
      width: "100%",
      marginLeft: "10%",
      padding: "20px",
      


      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          
          marginLeft: "0%",
          padding: "0px",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          marginLeft: "0rem",
          padding: "0px",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
          marginLeft: "0rem",
        },
      },
    },
  },
  FormBox: {
    "&.MuiBox-root": {
      width: 400,
      maxWidth: "100%",
      height: "5%",
      margin: "5%",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          maxWidth: "90%",
          height: "0%",
          margin: "3%",
        }
      },
     
    },
  },
  FormButton: {
    "&.MuiButton-root": {
      marginLeft: "3%",
      marginTop: "5%",
      // padding:"10px 15px",
      borderRadius: "1rem",
      backgroundColor: "#1a55ad",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiButton-root": {

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiButton-root": {
          marginLeft: "5%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiButton-root": {
          marginLeft: "5%",
        },
      },
    },
  },
  contactInfo: {
    "&.MuiTypography-root": {
      fontSize: "2.3rem",
      marginTop: "6rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.5rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.8rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2.2rem",
        },
      },
    },
  },

  contactBox: {
    "&.MuiBox-root": {
      
      width:"100%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
         padding:"1rem",
         width:"90%"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          padding:"1rem",
         width:"90%",
         marginLeft:"1.5rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
          padding:"1rem",
          marginLeft:"2rem"
        },
      },
    },
  },
  India: {
    "&.MuiTypography-root": {
      marginTop: "2%",
      marginBottom:"2%",
      fontWeight: "900",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          marginTop: "0%",
        }
      },
     
    },
  },
  contactText: {
    "&.MuiTypography-root": {
      fontSize: "1.1rem",
      marginRight: "12rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.7rem",
          marginRight: "1rem",

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          marginRight: "8rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          marginRight: "22rem",
        },
      },
    },
  },
  contactText1: {
    "&.MuiTypography-root": {
      fontSize: "1.1rem",
      marginLeft: "-40px",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.7rem",

        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
        }
      },
     
    },
  },
  form:{
    width:"70%",
    marginTop:"5%",
    padding:"2%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width:"80%"
    },
  },
  button:{
    backgroundColor:"#ffdc71",
    border:"0.1px solid black",
    width:"20%",
    borderRadius:"30px",
    marginTop:"2%",
    padding:"5px"
  },
  wholecontact:{
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      marginLeft:"10%"
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      marginLeft:"10%"
    },
    [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
      marginLeft:"10%"
    },
   
  }


}))



const Contactus = () => {
  const form = useRef();
  const classes = useStyle();


  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ein1lea', 'template_5uux0kf', form.current, 't7Z1vqEaJvgvqGF43')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
    showResult(true);
  };
  return (
    <Box className={classes.bgStyle}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: "5%" }}>
          <Box className={classes.formStyle}  >
          <form ref={form} className={classes.wholecontact} onSubmit={sendEmail}>
            <input className={classes.form} type="text" placeholder="Name" name="user_name" /><br/>
            <input className={classes.form} type="email" placeholder="Email Address" name="user_email" /><br/>
            <input className={classes.form} type="phone" placeholder="Phone Number" name="user_phone_number" /><br/>
            <textarea className={classes.form} placeholder="Message" name="message" /><br/>
            <input className={classes.button} type="submit" value="Send" />
    </form>

          </Box>

        </Grid>


        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Box className={classes.contactBox}>
            <Typography className={classes.contactInfo}>
              <b>Contact Info</b>
            </Typography>

            <hr />
            <Box >
              <Typography className={classes.India} >INDIA</Typography>
              <Box style={{ display: "flex" }}>
                <Box >
                  <Typography className={classes.contactText}>+91 9689736346</Typography>
                  <Typography className={classes.contactText}>moryapack.1350@gmail.com</Typography>
                </Box>
                <Box >
                  <Typography className={classes.contactText1}> +91 8975818090 </Typography>
                  <Typography className={classes.contactText1}></Typography>
                </Box>
              </Box>

              <hr />

              <Box style={{ textAlign:"start" }}>
                <Box >
                  <Typography className={classes.contactText}>Office & Factory:</Typography>
                  <Typography className={classes.contactText}>At Bhamboli, Post- Wasuli, Near Elite Company, Tal Khed, Dist. Pune 410501</Typography>


                </Box>
               
              </Box>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Contactus;