import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Main from "../assets/Image/Home/mainbox.png";
import altech from "../assets/Image/Home/alltech.png";
import zoomlion from "../assets/Image/Home/zoomlion.png";
import taural from "../assets/Image/Home/taural.jpg";
import takshi from "../assets/Image/Home/takshi.jpeg";
import papl from "../assets/Image/Home/papl.png";
import genie from "../assets/Image/Home/genie.jpg";
import forbes from "../assets/Image/Home/forbes.png";
import fintube from "../assets/Image/Home/fintube.png";
import work1 from "../assets/Image/Home/Plywood Box.webp";
import work2 from "../assets/Image/Home/Alluminumfoil.jpg";
import work3 from "../assets/Image/Home/insidecontainer.jpg"
import work4 from "../assets/Image/Home/Full pinewood box.jpeg";
import work5 from "../assets/Image/Home/Wooden Crate.jpeg";
import work6 from "../assets/Image/Home/Plywood Pinewood Box.jpg";
import { Carousel } from 'react-responsive-carousel'; 


const useStyle = makeStyles(() => ({
  landing: {
    backgroundColor:"#c4d06896",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      "&.MuiBox-root": {
        paddingBottom:"25%"
      }
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      "&.MuiBox-root": {
        paddingBottom:"25%" 
      }
    },
    
  },
  mainimg:{
    backgroundRepeat:"no-repeat",
    width:"120%",
    paddingBottom:"15%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width:"100%"
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width:"100%"
    },
    [`@media screen and (min-width:769px) and (max-width:899px)`]: {
      width:"100%"
    },
    [`@media screen and (min-width:900px) and (max-width:1200px)`]: {
      width:"100%"
    },
    
  },
  name:{
    "&.MuiTypography-root": {
    fontSize:"55px",
    marginLeft:"5%",
    paddingTop:"30%",
    fontWeight:"900",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      "&.MuiTypography-root": {
        fontSize:"32px",
        marginLeft:"5%",
        marginTop:"-30%",
        textAlign:"center",
        marginBottom:"3%",
      }
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      "&.MuiTypography-root": {
        fontSize:"32px",
        marginLeft:"5%",
        marginTop:"-40%",
        textAlign:"center",
        marginBottom:"3%",
      }
    },
    [`@media screen and (min-width:769px) and (max-width:899px)`]: {
      "&.MuiTypography-root": {
        fontSize:"32px",
        marginLeft:"5%",
        marginTop:"-40%",
        textAlign:"center",
        marginBottom:"3%",
      }
    },
    [`@media screen and (min-width:900px) and (max-width:1200px)`]: {
      "&.MuiTypography-root": {
        fontSize:"52px",
        marginLeft:"5%",
        marginTop:"-40%",
        textAlign:"center",
        marginBottom:"3%",
      }
    },
    }
  },
  subname:{
    "&.MuiTypography-root": {
      marginLeft:"5%",
      fontSize:"18px",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"18px",
          marginLeft:"5%",
          textAlign:"center",
          
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"15px",
          marginLeft:"5%",
          textAlign:"center",
          
        }
      },
      [`@media screen and (min-width:769px) and (max-width:899px)`]: {
        "&.MuiTypography-root": {
          fontSize:"15px",
          marginLeft:"5%",
          textAlign:"center",
          
        }
      },
      [`@media screen and (min-width:900px) and (max-width:1200px)`]: {
        "&.MuiTypography-root": {
          fontSize:"22px",
          marginLeft:"5%",
          textAlign:"center",
          
        }
      },
    }
  },
  Ourclients:{
    "&.MuiTypography-root": {
    textAlign:"center",
    marginTop:"15%",
    marginBottom:"5%",
    
    }
  },
  clienttxt:{
    "&.MuiTypography-root": {
      fontSize:"28px",
      // borderBottom:"0.1px solid black", 
      marginTop:"3rem",
      textAlign:"center",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"22px",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"25px",
        }
      },
    }
  },
  cleintimg:{
    display:"flex",
    borderBottom:"0.1px solid black",
    borderTop:"0.1px solid black",
    padding:"1%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width:"100%",
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      
    },
  },
  imgcompany:{
    width:"8%",
    marginLeft:"3.8%",
    height:"10%",
    marginTop:"1rem",
    alignItems:"center"
    
  },
  work:{
    display:"flex",
    marginTop:"5%",
    marginBottom:"2rem",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      "&.MuiBox-root": {
       marginBottom:"2rem"
      }
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      "&.MuiBox-root": {
       
      }
    },
  },
  workimg:{
    width:"20%",
    marginLeft:"10%"
  },
}));





const Home = () => {
  const classes = useStyle();
  return (
  <Box>
    <Box className={classes.landing}>
    <Grid container spacing={0}>
      <Grid item xs={12} lg={6} md={12}>
        <img className={classes.mainimg} src= {Main} />
      </Grid>

      <Grid item xs={12} lg={6} md={12}>
        <Typography className={classes.name}>Morya Packaging</Typography>
        <Typography className={classes.subname}>Industrial Packaging Solutions Providing Durable</Typography>
        <Typography className={classes.subname}>and Reliable Packaging Solutions</Typography>
      </Grid>
    </Grid>
  </Box>

  <Box className={classes.Ourclients}>
    <Typography className={classes.clienttxt} >Our Clients</Typography>
    <Box className={classes.cleintimg}>
    <img className={classes.imgcompany}  src={altech}/>
      <img className={classes.imgcompany}  src={zoomlion}/>
      <img className={classes.imgcompany}  src={taural}/>
      <img className={classes.imgcompany} src={takshi}/>
      <img className={classes.imgcompany}  src={papl}/>
      <img className={classes.imgcompany} src={genie}/>
      <img className={classes.imgcompany}  src={forbes}/>
      <img className={classes.imgcompany} src={fintube}/>
    
    </Box>
  </Box>
    
  <Box className={classes.Ourclients}>
    <Typography className={classes.clienttxt}>Our Work</Typography>
    <Box className={classes.work}>
      <img className={classes.workimg} src={work1} />
      <img className={classes.workimg} src={work2} />
      <img className={classes.workimg} src={work3} />
    </Box>

    <Box className={classes.work}>
      <img className={classes.workimg} src={work4} />
      <img className={classes.workimg} src={work5} />
      <img className={classes.workimg} src={work6} />
    </Box>
  </Box>
  </Box>
    

  );
};

export default Home;