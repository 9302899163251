import { Box, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from "react-router-dom";
import { PageRouteLinks } from "../Components/routing/RoutePaths";
import logo from "../assets/Image/logo.png";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';


const useStyle = makeStyles(theme => ({
    favoriteIconStyle: {
        "&.MuiSvgIcon-root": {
            marginBottom: "0px",
            width: "8%",

        }
    },
    arrow: {
        "&.MuiSvgIcon-root": {
            width: "2rem",
            height: "2rem",
        },
        position: "fixed",
        bottom: "20px",
        right: "5px",
        zIndex: "99",
        cursor: "pointer",
        backgroundColor: "#F8D057",
        padding: "5px",
        color: "white",
        borderRadius: "2rem",
        [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        
        },
    },
    dividerStyle: {
        "&.MuiDivider-root": {
            color: "#F1AD15",
            height: "200px",
            border: "1px solid #93ff40c6",
            [`@media screen and (min-width:0px) and (max-width:480px)`]: {
                marginLeft:"8%",
            },
        }
    },
    footer:{
        [`@media screen and (min-width:0px) and (max-width:480px)`]: {
            paddingInline:"2rem",
            marginTop:"0px",
            borderTop:"0.5px solid black",
            textAlign:"center",
            
        },
    },
    link: {
        textDecoration: "none"
    },
    "&.hover": {
        textDecorationColor: "white"
    }

}));
const scroll = () => {
    window.scrollTo(0, 0);
};

const PageFooter = () => {
    const classes = useStyle();
    const navigate = useNavigate();

    return (
        <Box>
            <Box style={{
                background: "#dddddd",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2rem"
            }}
            >
                <Typography>Morya Packaging</Typography>
                <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                        borderColor: "#F8D057",
                        marginLeft: "1rem",
                        marginRight: "1rem"
                    }}
                />
                {/* <Divider orientation="vertical" flexItem className={classes.dividerStyle} /> */}
                <List> 
                    {
                        PageRouteLinks.map(link => {
                            return (
                                <ListItem key={link.id} >
                                    <Link href="#" style={{
                                        color: "black",
                                        fontSize: "14px",
                                    }}
                                        className={classes.link}
                                        onClick={scroll}
                                        to={link.path}>
                                        {link.label}
                                    </Link>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Box>
            <Box>
            <Typography  className={classes.footer} sx={{ fontSize: "80%", padding: "0.5rem", paddingLeft:"1rem", textAlign:"center" }}>2023 Morya packaging. All rights reserved. Made with love from India, for the world</Typography>
            </Box>
            <KeyboardDoubleArrowUpIcon onClick={scroll} className={classes.arrow} />
        </Box>
    )
}

export default PageFooter;