import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid, Card } from "@mui/material";
import About_bg from "../assets/Image/About_bg.jpg";
import Video from "../assets/Image/Tech_Video.mp4";
import Crane from "../assets/Image/About/Crane.png"



const useStyle = makeStyles(() => ({
  Heading: {
    "&.MuiGrid-root": {
      marginInline: "28%",
      margin: "2%",
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: "3rem",
      margin: "auto",
      textAlign: "center",
      marginTop: "1%",
      color: "#243659",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2rem",
         
          textAlign: "center",
          marginTop: "1%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2.5rem",
          margin: "auto",
          marginTop: "1%",

        }
      },
      [`@media screen and (min-width:769px) and (max-width:900px)`]: {
        "&.MuiTypography-root": {
          fontSize: "3.4rem",
          textAlign: "center",
          marginTop: "1%",
        },
      },

      [`@media screen and (min-width:901px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "3.4rem",
          textAlign: "center",
          marginTop: "1%",
        },
      },
    },
  },

  text:{
    "&.MuiTypography-root": {
      textAlign:"center",
      fontSize:"1.5rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          textAlign:"center",
          fontSize:"1rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.4rem",
        },
      },
      
    },
  },
  DriveHeading:{
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "2rem",
      color: "#084298",
      padding: "3%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          padding: "5%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.5rem",
          padding: "5%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.8rem",
          padding: "5%",
        },
      },
      
    },
  },

  Video:{
    borderRadius: "2rem",
    width: "90%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width: "100%",
      borderRadius: "1.2rem",

    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "100%",
      height:"100%",
      borderRadius: "1.5rem",
    },
    [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
      width: "100%",
      height:"100%",
      borderRadius: "2rem",
    },
  },

  para:{
    "&.MuiTypography-root": {
      paddingBottom: "1rem",
      fontSize: "1.2rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.8rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
         
        },
      },
      
    },
  },
  ThreeTechText:{
    "&.MuiTypography-root": {
      textAlign: "center", 
      fontSize: "2rem", 
      paddingTop: "4rem",

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.4rem", 
          paddingTop: "2rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.6rem", 
          paddingTop: "2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
         
        },
      },
    }
  },
  TechLine:{
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "2rem",
      color: "#F8D057",
      

      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          paddingBottom: "5rem",
          fontSize: "1.4rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          paddingBottom: "4rem",
          fontSize: "1.6rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          paddingBottom: "4rem",
        },
      },
    }
  },
  Box:{
    
    "&.MuiBox-root":{
      marginLeft: "4rem",
      marginTop:"4rem",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          marginLeft: "2rem",  
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          marginLeft: "2.5rem", 
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
         
        },
      },
    }
   
  },

  Box1:{
    
    "&.MuiBox-root":{
      backgroungColor: "transparent",
      width: "20%",
      marginLeft: "3%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiBox-root": {
          width: "40%",
          marginLeft: "5%", 
          marginBottom:"2rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiBox-root": {
          width: "40%",
          marginLeft: "5%", 
          marginBottom:"2.5rem"
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiBox-root": {
         
        },
      },
    }
   
  },
  SecondGrid:{
    "&.MuiGrid-root":{
      marginTop: "4rem" ,
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiGrid-root": {
          marginTop:"1rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiGrid-root": {
          marginTop:"1rem" 
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiGrid-root": {
         
        },
      },
    }
  },

  images: {
    width: "60%",
    marginLeft: "2.5rem",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width: "70%",
      marginLeft: "1rem",

    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "60%",
      marginLeft: "2.5rem",

    },
    [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
      width: "70%",
      marginLeft: "1.5rem",

    },

  },
  BoxHeading:{
    "&.MuiTypography-root": {
      
      textAlign: "center",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.7rem"
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
         
        },
      },
    }
  },
  main: {
    // backgroundImage: `url(${About_bg})`,
    margin: "auto",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "50px",
  },
}));

const About = () => {
  const classes = useStyle();
  return (
    <Card className={classes.main}>
      <Box>
      <Typography className={classes.heading}>Your Ultimate Packing Destination</Typography>
      </Box>
      <Typography  style={{}}>
        <Typography className={classes.text} >
        Crafting Superior Packaging Experiences 
        </Typography>
        <Typography className={classes.text} >
        for Our Clients' Delight
        </Typography>
      </Typography>
      <Box style={{ marginTop: "5%" }}>
        <Typography className={classes.DriveHeading}
          style={{
            
          }}
        >
        Empowering Packaging Innovation with Digital Transformation
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={6} md={8} sm={6} xs={12}>
            <img className={classes.Video}
              src={Crane}
             
            />
          </Grid>
          <Grid item lg={6} md={4} sm={6} xs={12}>
            <Typography className={classes.para}>
            <b>MORYA PACKAGING</b>  has been a very distinguished name of the industry economy betrothed in the manufacture and trade of a highly durable and longer serving gamut of plywood and pinewood products. Our offered merchandises in which we have been willingly submerged include assortments like Pinewood Pallets, Wooden and Plywood Box, Pinewood Crates, Corrugated Box etc.
The personnel engaged in these manufacture process are some of the highly chosen men of art who have been able to acclaim a very rich and sound proficiency in this industry arena. We have been assisted and accompanied with the best of amenities and facilities
at our working premise which help us in coping up standing tall with our respective customer demand. With all this we have been able to cater and serve every particular demand of our customers as may suit in conformance to the needs of our customers.
            </Typography>
            {/* <Typography style={{paddingBottom:"1rem" ,fontSize:"1.2rem"}}>The days of “build it and they will come” are long gone; marketing your website/app in the digital realm often costs less than traditional offline marketing. We, at Allentics, will help you understand, customize, and execute different parts of a digital marketing strategy (SEO, PPC, backlinks, etc.) for maximum impact on the web.</Typography> */}
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginTop: "1rem" }}>
        <Typography className={classes.ThreeTechText}
          
        >
          <b>Key Benefits of Triple Wall Boxes</b>
        </Typography>
        <Typography className={classes.TechLine}
        >
          <b>___________</b>
        </Typography>


        {/* <.......................................Box...................................> */}
          <Box>
            <ul>
              <li>Maximum protection. Triple wall boxes are stronger than yours average cardboard boxes.</li>
              <li>Cost-Effective and Sustainable. Best alternate for word.</li>
              <li>Great for Branding</li>
              <li>Light Weight</li>
              <li>Recyclable and Resuable</li>
              <li>Offer Flexibility</li>
              <li>Easy stackable</li>
              <li>Suitable for all type of industries</li>


            </ul>
          </Box>

        
      </Box>
    </Card>
  );
};

export default About;