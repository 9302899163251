import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ServiceBgImg from "../assets/Image/Service/BgImg.jpg"
import Europallet from "../assets/Image/Service/euro pallete collars frame.png";
import Corrugated from "../assets/Image/Service/corrugated boxes.png";
import Pinewood from "../assets/Image/Service/Pinewood Box.webp";
import Reversible from "../assets/Image/Service/Reversible pallets.webp";
import Aluminium from "../assets/Image/Service/PE Film.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const useStyle = makeStyles(() => ({
  title: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: "3rem",
      paddingTop: "3%",
      paddingBottom: "3%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.3rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2rem",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:1024px)`]: {
        "&.MuiTypography-root": {
          fontSize: "2.5rem",
        },
      },
    },
  },
  appimg: {
    width: "80%",
    marginTop: "70px",
    marginLeft: "17%",
    borderRadius: "5%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {
      width: "65%",
      marginTop: "3rem",
      height:"80%",

    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "65%",
      marginTop: "4rem",
      height:"80%",
    },
    [`@media screen and (min-width:769px) and (max-width:899px)`]: {
      width: "65%",
      marginTop: "3rem",
      height:"80%",
    },
  },
  appimg1: {
    width: "80%",
    marginTop: "70px",
    marginLeft: "-10%",
    borderRadius: "5%",
    [`@media screen and (min-width:0px) and (max-width:480px)`]: {

      width: "65%",
      marginTop: "10px",
      marginLeft: "18%",
      height:"80%",
    },
    [`@media screen and (min-width:481px) and (max-width:768px)`]: {
      width: "65%",
      marginTop: "50px",
      marginLeft: "18%",
      height:"70%",
    },
    [`@media screen and (min-width:769px) and (max-width:899px)`]: {
      width: "65%",
      marginTop: "50px",
      marginLeft: "18%",
      height:"70%",
      
    },
  },
  apptxt: {
    "&.MuiTypography-root": {
      marginTop: "11%",
      textAlign: "left",
      fontSize: "1.5rem",
      marginLeft: "10%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.8rem",
          marginTop: "1%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.3rem",
          marginTop: "0%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:899px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.4rem",
          marginTop: "0%",
        },
      },
    },
  },
  apptxt1: {
    "&.MuiTypography-root": {
      marginTop: "11%",
      textAlign: "left",
      fontSize: "1.5rem",
      marginLeft: "10%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize:"0.8rem",
          marginTop: "1%",
          marginTop: "15%",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.3rem",
          marginTop: "0%",
          marginTop: "15%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:899px)`]: {
        "&.MuiTypography-root": {
          fontSize:"1.4rem",
          marginTop: "0%",
          marginTop: "15%",
        },
      },
    },
  },
  paraText: {
    "&.MuiTypography-root": {
      textAlign: "left",
      width: "80%",
      marginTop: "5%",
      fontSize: "1.2rem",
      marginLeft: "10%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        "&.MuiTypography-root": {
          fontSize: "0.7rem",
        }
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1rem",
          marginTop: "3%",
        }
      },
      [`@media screen and (min-width:769px) and (max-width:899px)`]: {
        "&.MuiTypography-root": {
          fontSize: "1.1rem",
          marginTop: "3%",
        },
      },
    }
  },
  horizontal: {
    margin: "auto",
    width: "90%",
    marginTop: "10%",
  },
  main: {
    // backgroundImage: `url(${ServiceBgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

  },
}));

const Services = () => {
  const classes = useStyle();
  return (
    <Box className={classes.main}>
      <Typography className={classes.title}>Our Services</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
          <div data-aos="fade-right">
            <img className={classes.appimg} src={Europallet} alt="" />
            </div>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12} >
            <Typography className={classes.apptxt}>
              EURO PALLET COLLAR
            </Typography>
            <Typography className={classes.paraText}

            >
              Euro pallet collars are clever European Innovations Designed with resusability and practically in mind. Euro pallet are collapsible wooden frames that affix easily to standard 1200x800 mm euro pallet. Beginning with first collar seated neatly around the edge of a 48x32 euro pallet, each pallet collar or pallet frame is stacked vertically upon one another to built a box using corner hingers that lock in place with removable pins. Users simply nail a wooden top on the pallet collar to create a durable, resuable box.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt1}>
              CORRUGATED BOXES
            </Typography>
            <Typography
              className={classes.paraText}
            >
              Our Tripple wall boxes are strong enough to be a subtitute for wood. These boxes are traditionally used for shipping heavy duty items such as metal parts, chemicals, export, etc. this type of corrugated design is commonly used for larger container and heavy load. At Morya Packaging, triple wall boxes are the strongest form of a corrugated case made from heavy duty linear board.
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
          <div data-aos="fade-left">
            <img className={classes.appimg1} src={Corrugated} alt="" />
          </div>
          </Grid>
        </Grid>
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
          <div data-aos="fade-right">
            <img className={classes.appimg} src={Reversible} alt="" />
            </div>
          </Grid>
          <Grid itemlg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt}>
              PINEWOOD PALLETS
            </Typography>
            <Typography
              className={classes.paraText}
            >
              We offer to our clients high quality of two way and four way pallets which are an ideal choice for packaging and transporting big items. Manufactured from pinewood these pallets have the facility of getting lifted from two / four sides, which brings further ease in carrying and loading.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt1}>
              PLYWOOD / PINEWOOD BOX
            </Typography>
            <Typography
              className={classes.paraText}
            >
              We Morya Packaging are on of the renowned manufacturers and suppliers of wooden packaging products. Packaging boxes offered by us are highly appreciated in the market for dimensional accuracy, light weight, good load bearing capacity and high endurance. Manufactured using quality wood and other raw material, these packaging boxes can be utlized to pack goods such as medicines, engineering equipment, food products, glass and fragile items.
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
          <div data-aos="fade-left">
            <img className={classes.appimg1} src={Pinewood} alt="" />
          </div>
          </Grid>
        </Grid>
      </Box>

      <hr className={classes.horizontal} />

      <Box>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
          <div data-aos="fade-right">
            <img className={classes.appimg} src={Aluminium} alt="" />
          </div>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography className={classes.apptxt}>
              ALUMINIUM FOIL 
            </Typography>
            <Typography
              className={classes.paraText}
            >
              Aluminium foil provides a complete barrier to light, oxygen. moisture and bacteria. For this reason, foil is used extensively in food and pharmaceutical packaging. It is also used to make aseptic packaging that enables storage of perishable goods without refrigeration.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <hr className={classes.horizontal} />

      
    </Box>
  );
};

export default Services;